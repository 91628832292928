export function isServiceWorkerSupported(): boolean {
  if (typeof window !== 'undefined') {
    const host = window.location.host
    const queryParams = new URLSearchParams(window.location.search)
    if (
      (host.includes('pwa') || queryParams.get('serviceWorker') === 'on') &&
      'serviceWorker' in navigator
    ) {
      return true
    }
  }

  return false
}

const isLocalHost = () => {
  if (typeof window !== 'undefined') {
    return window.location.hostname === 'localhost'
  }
  return false
}

export async function registerServiceWorker(): Promise<
  ServiceWorkerRegistration | undefined
> {
  if (isServiceWorkerSupported()) {
    const sw = isLocalHost() ? '/sw-dev.js' : '/sw.js'
    return navigator.serviceWorker.register(sw, {
      scope: '/',
      type: 'classic',
    })
  }
}
